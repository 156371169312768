import React, { Component } from 'react'
import Slider from "react-slick";
import { AiFillHtml5, AiFillInstagram, AiFillGooglePlusCircle } from "react-icons/ai";
import { DiCss3, DiSass } from "react-icons/di";
import { SiD3Dotjs, SiNodedotjs, SiPhp } from "react-icons/si";
import { GrReactjs } from "react-icons/gr";

import Typical from "react-typical"
export default class AboutPage extends Component {

    render() {
        var settings = {
            dots: true,
            infinite: true,
            speed: 300,
            slidesToShow: 4,
            slidesToScroll: 4,
            initialSlide: 0,
            autoplay: true,
            autoplayspeed: 100,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return (

            <div>

                <div id="about" className="paddsection">
                    <div className="container">
                        <div className="row justify-content-between">
                            <div className="col-lg-4 ">
                                <div className="div-img-bg">
                                    <div className="about-img">
                                        <img src="images/hillAvatar.JPG" className="img-responsive" alt="me" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <div className="about-descr">

                                    <p className="p-heading">Frontend Developer &amp; the leader &amp; as an inspirational person</p>
                                    <p className="separator">I program and design optimized interfaces with a focus on real value created every time, and leading a team truly makes me shine. Creativity flows not only through solving problems and exploring new possibilities at work, but also through every aspect of how I live and explore the world around me.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="services">
                    <Slider {...settings} className='container pt-5 pb-5'>
                        <div className="services-block">
                            <i>
                                <AiFillHtml5 />
                            </i>
                            <span>HTML5</span>
                            <p className="separator">Building the backbone of the web with semantic elements for clean, accessible, and structured content.</p>
                        </div>
                        <div className="services-block">
                            <i>
                                <DiCss3 />
                            </i>

                            <span>CSS</span>
                            <p className="separator">Styling web pages to bring ideas to life, ensuring responsiveness and consistency across all devices.</p>
                        </div>
                        <div className="services-block">
                            <i>
                                <DiSass />
                            </i>

                            <span>SASS</span>
                            <p className="separator">Writing scalable, maintainable CSS with nested rules and variables to keep stylesheets organized and efficient.</p>
                        </div>
                        <div className="services-block">
                            <i>
                                <GrReactjs />
                            </i>

                            <span>REACTJS</span>
                            <p className="separator">Developing dynamic, component-based user interfaces that deliver seamless and interactive user experiences.</p>
                        </div>

                        <div className="services-block">
                            <i>
                                <SiD3Dotjs />
                            </i>

                            <span>D3</span>
                            <p className="separator">Transforming data into interactive, meaningful visualizations with powerful, flexible JavaScript libraries.</p>
                        </div>

                        <div className="services-block">
                            <i>
                                <SiNodedotjs />
                            </i>

                            <span>NodeJS</span>
                            <p className="separator">Transforming data into interactive, meaningful visualizations with powerful, flexible JavaScript libraries.</p>
                        </div>

                        <div className="services-block">
                            <i>
                                <SiPhp />
                            </i>

                            <span>PHP</span>
                            <p className="separator">Transforming data into interactive, meaningful visualizations with powerful, flexible JavaScript libraries.</p>
                        </div>

                    </Slider>

                </div>

            </div>
        )
    }
}
